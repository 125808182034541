.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.model .conTip {
  text-align: justify;
  margin-bottom: 20px;
}
.model .itemLabel {
  margin: 10px 0;
}
.OpStudentstatistics > div {
  width: 100%;
  justify-content: space-between;
}
.OpStudentstatistics .el-form {
  display: flex;
}
.OpStudentstatistics .el-form .el-form-item__label {
  padding: 0 8px 0 0 !important;
}
.OpStudentstatistics .el-form .el-form-item {
  margin-bottom: 0;
}
.OpStudentstatistics .el-form .btns .el-form-item__content {
  margin-left: 0 !important;
}
.OpStudentstatistics .completeStates .el-form-item__label {
  min-width: 7rem;
}
.OpStudentstatistics .completeStates .el-form-item__content {
  margin-left: 7rem !important;
}
.custom .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #5c6be8;
  border-color: #5c6be8;
}
.custom .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #5c6be8;
}
.el-select__tags-text {
  min-width: 2rem !important;
}
.el-selectBox .el-select .el-tag {
  min-width: 10px !important;
}
